import React, { useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import Loader from 'components/Loader'
import Home from 'Home'
import history from './history'
import 'App.scss'

function App() {
  const dispatch = useDispatch()
  const { isLogged } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  return (
    <>
      <Suspense fallback={<Loader center />}>
        {isLogged && (
        <Router history={history}>
          <Switch>
            <Route component={Home} path="/" />
          </Switch>
        </Router>
        )}
      </Suspense>
    </>
  )
}

export default App
