export const ALL_SIGNALS_TYPES = [
  'A',
  'APPROCHETS',
  'APPROETSA',
  'ARRET',
  'ARRET A',
  'ARRET VOY',
  'ATC',
  'BP DIS',
  'BP EXE',
  'BP FIN',
  'CAB E',
  'CAB R',
  'CAB S',
  'CARRE',
  'CC EXE',
  'CC FIN',
  'CHEVRON',
  'CV',
  'D',
  'DD',
  'DESTI',
  'DIVERS',
  'DEPOT',
  'FIN CAT',
  'G',
  'GA',
  'GABARIT',
  'GARE',
  'HEURT...',
  'ID',
  'IDD',
  'IDP',
  'IMP',
  'JAL MAN',
  'L',
  'LGR',
  'LIMITETS',
  'LM',
  'MIBLAN VER',
  'P',
  'PN',
  'PN...',
  'R',
  'REPER VIT',
  'REP TGV',
  'REV',
  'S',
  'SECT',
  'SG HEURT',
  'SIFFLER',
  'STOP',
  'STOP A',
  'TECS',
  'TIV D FIXE',
  'TIV D MOB',
  'TIV PENDIS',
  'TIV PENEXE',
  'TIV PENREP',
  'TIV R MOB',
  'TIVD B FIX',
  'TIVD C FIX',
  'TSCS',
  'Z',
  'SG TGV',
  'SIG FICTIF',
]

export const SIGNALS_PANELS = [
  'SIFFLER',
  'G',
  'DIVERS',
  'CHEVRON',
  'APPROETSA',
  'DD',
  'ATC',
  'DESTI',
  'PN',
  'DEPOT',
  'TECS',
  'ARRET VOY',
  'TAB DIVERS',
  'PN...',
  'D',
  'TIV D FIXE',
  'LIMITETS',
  'TIV E TRAM',
  'Z',
  'TIV D MOB',
  'GARE',
  'GABARIT',
  'STOP',
  'ARRET A',
  'Ex',
  'REP TGV',
  'CAB S',
  'REPER VIT',
  'R',
  'SECT',
  'CAB E',
  'CC EXE',
  'LM',
  'TIV R MOB',
  'CC FIN',
  'BP EXE',
  'BP FIN',
  'CAB R',
  'TIVD C FIX',
  'BP DIS',
  'SLM',
  'BIMODE',
  'REV',
  'TIV PENDIS',
  'TIVD B FIX',
  'L',
  'TIV PENEXE',
  'IMP',
  'TIV PENREP',
  'GIVRE',
  'SLD',
  'TSCS',
  'JAL MAN',
  'TIV A TRAM',
  'ARRET',
  'Ct',
  'R17',
  'MIBLAN VER',
  'JAL ARRET',
  'SAC',
  'APPROCHETS',
  'SG DIR',
  'REP ITIN',
  'LGR',
  'P',
  'TIV R TRAM',
  'SG HEURT',
  'HEURT...',
  'VOIE CONV',
  'SG MANOEUV',
  'IDP',
  'PAD',
  'BIMODE A',
  'SG LIMVIT',
  'B',
  'FIN CAT',
  'Aig M',
  'SG DIVERS',
  'MV',
  'STOP A',
  'CARRE (CH)',
  'FLECHE ACC',
  'Aig T',
  'SIG A TRAM',
  'TUNNEL',
  'S',
  'SG TGV',
  'SIG FICTIF',
]
