import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MAP_TRACK_SOURCE, MAP_URL } from 'common/Map/const'
import { OBJECTS_KEYS } from 'common/Map/Layers/LayersProperties'

import LinearObjects from 'common/Map/Layers/LinearObjects'
import JointsDeZones from 'common/Map/Layers/JointsDeZones'
import SignalsSch from 'common/Map/Layers/SignalsSch'
import SignalsGeo from 'common/Map/Layers/SignalsGeo'
import PointsRemarquables from 'common/Map/Layers/PointsRemarquables'

function ObjectsLayer({ sourceLayer }) {
  const { displayedLayers, trackedSource } = useSelector(state => state.map)

  return displayedLayers.map(el => {
    if (el.isLinear) {
      return (
        <LinearObjects
          key={el.sourceTable}
          mapUrl={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
        />
      )
    }
    if (el.key === OBJECTS_KEYS.jdz) {
      return (
        <JointsDeZones
          key={el.sourceTable}
          mapUrl={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
        />
      )
    }
    if (el.key === OBJECTS_KEYS.pr) {
      if (trackedSource === MAP_TRACK_SOURCE.schematic) {
        return (
          <PointsRemarquables
            key={el.sourceTable}
            mapUrl={MAP_URL}
            sourceLayer={sourceLayer}
            sourceTable={el.sourceTable}
          />
        )
      }
    }
    if (el.key === OBJECTS_KEYS.signal) {
      if (trackedSource === MAP_TRACK_SOURCE.schematic) {
        return (
          <SignalsSch
            key={el.sourceTable}
            mapUrl={MAP_URL}
            sourceLayer={sourceLayer}
            sourceTable={el.sourceTable}
          />
        )
      }
      return (
        <SignalsGeo
          key={el.sourceTable}
          mapUrl={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
        />
      )
    }
    return null
  })
}

ObjectsLayer.propTypes = {
  sourceLayer: PropTypes.string.isRequired,
}

export default ObjectsLayer
