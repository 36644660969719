import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import commonLayerData from 'common/Map/Layers/commonlayers.json'
import geoLayerData from 'common/Map/Layers/geographiclayers.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'

function GeographicView() {
  const { geoMainLayer, geoServiceLayer } = geoLayerData
  const { trackNameLayer, lineNumberLayer } = commonLayerData
  return (
    <Source
      type="vector"
      url={`${MAP_URL}/chartis/v2/layer/map_midi_tronconditinerairevoie/mvt/geo/`}
      source-layer={OBJECTS_LAYERS.tivGeo}
    >
      <Layer
        {...geoMainLayer}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivGeo}
      />
      <Layer
        {...geoServiceLayer}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivGeo}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            visibility: 'visible',
          },
        }}
        id="geoNameVPLayer"
        source-layer={OBJECTS_LAYERS.tivGeo}
        filter={['==', 'TDV_mnemonique', 'VPL']}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 10,
            visibility: 'visible',
          },
        }}
        id="geoNameVSLayer"
        source-layer={OBJECTS_LAYERS.tivGeo}
        filter={['!=', 'TDV_mnemonique', 'VPL']}
      />
      <Layer
        {...{
          ...lineNumberLayer,
          layout: {
            ...lineNumberLayer.layout,
            'text-field': '{L_code}',
          },
        }}
        id="geoLineNumLayer"
        source-layer={OBJECTS_LAYERS.tivGeo}
      />
    </Source>
  )
}

export default GeographicView
