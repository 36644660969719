import React from 'react'
import { Source, Layer } from 'react-map-gl'

import { MAP_URL } from 'common/Map/const'
import commonLayerData from 'common/Map/Layers/commonlayers.json'
import schematicLayerData from 'common/Map/Layers/schematiclayers.json'
import { OBJECTS_LAYERS } from 'common/Map/Layers/LayersProperties'

function SchematicView() {
  const { trackNameLayer, lineNumberLayer } = commonLayerData
  const { schematicMainLayer, schematicServiceLayer, schematicTunnelLayer } = schematicLayerData

  const isVSCalibratedExpression = ['all',
    ['to-boolean', ['get', 'pk_sncf_debut']],
    ['to-boolean', ['get', 'pk_sncf_fin']],
    ['to-boolean', ['get', 'L_code']],
    ['to-boolean', ['get', 'V_nom']],
  ]

  return (
    <Source
      type="vector"
      url={`${MAP_URL}/chartis/v2/layer/map_midi_tronconditinerairevoie/mvt/sch/`}
      source-layer={OBJECTS_LAYERS.tivSch}
      id="tiv-tiles"
    >
      <Layer
        {...schematicServiceLayer}
        paint={{
          'line-width': 1,
          'line-color': ['case',
            isVSCalibratedExpression, '#999',
            '#111',
          ],
        }}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...schematicMainLayer}
        layout={{ visibility: 'visible' }}
        source-layer={OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...schematicTunnelLayer}
        source-layer={OBJECTS_LAYERS.tivSch}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 16,
            visibility: 'visible',
          },
        }}
        id="schNameVPLayer"
        source-layer={OBJECTS_LAYERS.tivSch}
        filter={['==', 'type_voie', 'VP']}
      />
      <Layer
        {...{
          ...trackNameLayer,
          layout: {
            ...trackNameLayer.layout,
            'text-field': '{V_nom}',
            'text-size': 10,
            visibility: 'visible',
          },
        }}
        id="schNameVSLayer"
        source-layer={OBJECTS_LAYERS.tivSch}
        filter={['!=', 'type_voie', 'VP']}
      />
      <Layer
        {...{
          ...lineNumberLayer,
          layout: {
            ...lineNumberLayer.layout,
            'text-field': '{L_code}',
            visibility: 'visible',
          },
        }}
        id="schLineNumLayer"
        source-layer={OBJECTS_LAYERS.tivSch}
      />
    </Source>
  )
}

export default SchematicView
