import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { MAP_URL } from 'common/Map/const'
import { OBJECTS_KEYS } from 'common/Map/Layers/LayersProperties'
import PointsRemarquablesLine from 'common/Map/Layers/PointsRemarquablesLine'

function PrLayer({ sourceLayer }) {
  const { displayedLayers } = useSelector(state => state.map)

  return displayedLayers.map(el => {
    if (el.key === OBJECTS_KEYS.prLine) {
      return (
        <PointsRemarquablesLine
          key={el.sourceTable}
          mapUrl={MAP_URL}
          sourceLayer={sourceLayer}
          sourceTable={el.sourceTable}
        />
      )
    }
    return null
  })
}

PrLayer.propTypes = {
  sourceLayer: PropTypes.string.isRequired,
}

export default PrLayer
