import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

const PointsRemarquables = ({
  mapUrl, sourceLayer, sourceTable,
}) => {
  const layerProps = {
    'source-layer': sourceTable,
    id: `${sourceTable}Layer`,
    type: 'circle',
    paint: {
      'circle-color': '#4d4f53',
      'circle-radius': 3,
    },
  }

  const layerPropsHighlighted = {
    'source-layer': sourceTable,
    id: `${sourceTable}Layer-highlighted`,
    type: 'circle',
    paint: {
      'circle-color': '#4d4f53',
      'circle-radius': 3,
    },
  }

  const filter = useMemo(() => ['in', 'id', 'selectedMtbfFeature'], ['selectedMtbfFeature'])

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/sch_thea/`}
    >
      <Layer {...layerProps} />
      <Layer {...layerPropsHighlighted} filter={filter} />
    </Source>
  )
}

PointsRemarquables.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
}

export default PointsRemarquables
