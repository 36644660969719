/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment";
import "moment/locale/fr";

function PopupContent(featureInfoHover) {
	const properties = featureInfoHover.features[0].properties
	switch (featureInfoHover.features[0].sourceLayer) {
		case "map_midi_tronconditinerairevoie":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Code Ligne : {properties.L_code}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>Libellé de ligne : </strong>{properties.RA_libelle_ligne}</div>
						<div className="my-2"></div>
						<div><strong>Libellé de voie : </strong>{properties.RA_libelle_voie}</div>
						<div><strong>Type de voie : </strong>{properties.type_voie}</div>
						<div className="my-2"></div>
						<div><strong>PK SNCF Début : </strong>{properties.pk_sncf_debut}</div>
						<div><strong>PK SNCF Fin : </strong>{properties.pk_sncf_fin}</div>
					</div>
				</>
			)
		case "phrit_pointremarquable":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>CI / CH : {properties.CI} / {properties.CH}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé CI : </strong>{properties.LIB_CI}</div>
						<div><strong>Libellé CH : </strong>{properties.LIB_CH_LON}</div>
						<div className="my-2"></div>
						<div><strong>EIC : </strong>{properties.eic}</div>
						<div className="my-2"></div>
						<div><strong>PK SNCF : </strong>{properties.PK_R}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.DDA_LOCAL).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.DFA_LOCAL).format("DD/MM/YYYY")}</div>
						<div className="my-2"></div>
						<div><strong>Rang tronçon : </strong>{properties.RG_TRONCON}</div>
						<div><strong>Indicateur particulier : </strong>{properties.IND_PR_PAR}</div>
						<div><strong>Indicateur GSMR : </strong>{properties.IND_GSMR}</div>
					</div>
				</>
			)
		case "cassini_pointremarquable":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>CI / CH : {properties.codeCI} / {properties.codeCH}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé CI : </strong>{properties.libelle}</div>
						<div><strong>Libellé CH : </strong>{properties.libelleLongCH}</div>
						<div className="my-2"></div>
						<div><strong>Numéro lieu : </strong>{properties.numeroLieu}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.dateDebutActivite).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.dateFinActivite).format("DD/MM/YYYY")}</div>
						<div className="my-2"></div>
						<div><strong>Indicateur particulier : </strong>{properties.indicateurParticulier}</div>
						<div><strong>Indicateur GSMR : </strong>{properties.indicateurGSMR}</div>
					</div>
				</>
			)
		case "map_midi_signal":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Signal : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé de gare : </strong>{properties.RA_libelle_gare}</div>
						<div><strong>Libellé de poste : </strong>{properties.RA_libelle_poste}</div>
						<div className="my-2"></div>
						<div><strong>Nom de la voie : </strong>{properties.V_nom}</div>
						<div><strong>PK SNCF : </strong>{properties.pk_sncf}</div>
						<div className="my-2"></div>
						<div><strong>Catégorie : </strong>{properties.RA_libelle_typeparentinstallationfixe}</div>
						<div><strong>Type d'implémentation : </strong>{properties.RA_libelle_typeimplantation}</div>
						<div><strong>Type de support : </strong>{properties.RA_libelle_typesupportsignal}</div>
						<div className="my-2"></div>
						<div><strong>En service : </strong>{properties.S_enService}</div>
						<div><strong>Nombre de flèches : </strong>{properties.S_nombreDeFleches}</div>

					</div>
				</>
			)
		case "map_midi_jointdezone":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Joint de zone</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Nom de la voie : </strong>{properties.V_nom}</div>
						<div><strong>PK SNCF : </strong>{properties.pk_sncf}</div>
						<div className="my-2"></div>
						<div><strong>Type : </strong>{properties.srType}</div>
					</div>
				</>
			)
		case "map_midi_brancheapv":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>APV : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé de gare : </strong>{properties.RA_libelle_gare}</div>
						<div><strong>Libellé de poste : </strong>{properties.RA_libelle_poste}</div>
						<div className="my-2"></div>
						<div><strong>Nom de la voie : </strong>{properties.V_nom}</div>
						<div><strong>PK SNCF : </strong>{properties.pk_sncf}</div>
						<div className="my-2"></div>
						<div><strong>Code branche : </strong>{properties.BAPV_codeBranche}</div>
						<div><strong>Direction : </strong>{properties.BAPV_direction}</div>

					</div>
				</>
			)
		case "map_midi_circuitdevoie":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Circuit de voie : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé de gare : </strong>{properties.RA_libelle_gare}</div>
						<div><strong>Libellé de poste : </strong>{properties.RA_libelle_poste}</div>
					</div>
				</>
			)
		case "map_midi_zep":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>ZEP : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé de gare : </strong>{properties.RA_libelle_gare}</div>
						<div><strong>Libellé de poste : </strong>{properties.RA_libelle_poste}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.RA_dateDebutActivite).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.RA_dateFinActivite).format("DD/MM/YYYY")}</div>
						<div className="my-2"></div>
						<div><strong>Train travaux interdits : </strong>{properties.Z_trainTravauxInterdits}</div>
					</div>
				</>
			)
		case "map_midi_groupementdezep":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Groupement de ZEP : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé de gare : </strong>{properties.RA_libelle_gare}</div>
						<div><strong>Libellé de poste : </strong>{properties.RA_libelle_poste}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.RA_dateDebutActivite).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.RA_dateFinActivite).format("DD/MM/YYYY")}</div>
						<div className="my-2"></div>
						<div><strong>Train travaux interdits : </strong>{properties.GDZEP_trainTravauxInterdits}</div>
					</div>
				</>
			)
		case "map_midi_elementcatenaire":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>SEL : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé du central sous-station : </strong>{properties.RA_libelle_centralsoustation}</div>
						<div><strong>Libellé du secteur : </strong>{properties.RA_libelle_grandmere}</div>
						<div><strong>Libellé du sous-secteur : </strong>{properties.RA_libelle_mere}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.RA_dateDebutActivite).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.RA_dateFinActivite).format("DD/MM/YYYY")}</div>
					</div>
				</>
			)
		case "map_midi_groupementcatenaire":
			return (
				<>
					<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
						<strong>Groupement de SEL : {properties.RA_libelle}</strong>
					</div>
					<div className="mapboxgl-popup-container">
						<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
						<div className="my-2"></div>
						<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
						<div><strong>Libellé du central sous-station : </strong>{properties.RA_libelle_centralsoustation}</div>
						<div className="my-2"></div>
						<div><strong>Date de début d'activité : </strong>{moment(properties.RA_dateDebutActivite).format("DD/MM/YYYY")}</div>
						<div><strong>Date de fin d'activité : </strong>{moment(properties.RA_dateFinActivite).format("DD/MM/YYYY")}</div>
					</div>
				</>
			)
			case "map_midi_itineraire":
				return (
					<>
						<div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
							<strong>Itinéraire : {properties.RA_libelle}</strong>
						</div>
						<div className="mapboxgl-popup-container">
							<div><strong>ID GAIA : </strong>{properties.OP_id}</div>
							<div className="my-2"></div>
							<div><strong>Libellé : </strong>{properties.RA_libelle}</div>
							<div className="my-2"></div>
							<div><strong>Code ligne du signal de destination : </strong>{properties.L_code_signalDestination}</div>
							<div><strong>Code ligne du signal d'origine : </strong>{properties.L_code_signalOrigine}</div>
							<div className="my-2"></div>
							<div><strong>Libellé du macaron de destination : </strong>{properties.RA_libelle_macaron_destination}</div>
							<div><strong>Libellé du macaron d'origine : </strong>{properties.RA_libelle_macaron_origine}</div>
							<div className="my-2"></div>
							<div><strong>Libellé du signal de destination: </strong>{properties.RA_libelle_signal_destination}</div>
							<div><strong>Libellé du signal d'origine: </strong>{properties.RA_libelle_signal_origine}</div>
							<div className="my-2"></div>
							<div><strong>Nom de la voie du signal de destination : </strong>{properties.V_nom_signalDestination}</div>
							<div><strong>Nom de la voie du signal d'origine : </strong>{properties.V_nom_signalOrigine}</div>
							<div className="my-2"></div>
							<div><strong>PK SNCF du signal de destination : </strong>{properties.pk_sncf_signalDestination}</div>
							<div><strong>PK SNCF du signal d'origine : </strong>{properties.pk_sncf_signalOrigine}</div>
						</div>
					</>
				)
		default:
			return null;
	}
}


PopupContent.propTypes = {
	featureInfoHover: PropTypes.object,
}

export default PopupContent
