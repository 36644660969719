import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

const JointsDeZones = ({
  mapUrl, sourceLayer, sourceTable,
}) => {
  const config = () => {
    const angleName = (sourceLayer === 'sch') ? 'angleSch' : 'angleGeo'

    return ({
      id: `${sourceTable}Layer`,
      type: 'symbol',
      minzoom: 12,
      layout: {
        'text-field': '{RA_libelle}',
        'text-font': [
          'Roboto Condensed',
        ],
        'text-size': 10,
        'text-offset': [2, 0],
        'icon-image': 'JDZB',
        'icon-size': 0.2,
        'text-anchor': 'center',
        'icon-rotation-alignment': 'map',
        'icon-rotate': ['get', angleName],
        'text-rotate': ['get', angleName],
        'icon-allow-overlap': false,
        'icon-ignore-placement': false,
        'text-allow-overlap': false,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#555',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-blur': 1,
      },
    })
  }

  const layerProps = {
    ...config(),
    'source-layer': sourceTable,
  }

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/`}
    >
      <Layer {...layerProps} />
    </Source>
  )
}

JointsDeZones.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
}

export default JointsDeZones
