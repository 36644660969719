import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

const PointsRemarquablesLine = ({
  mapUrl, sourceLayer, sourceTable,
}) => {
  const layerProps = {
    'source-layer': sourceTable,
    id: `${sourceTable}Layer`,
    type: 'circle',
    paint: {
      'circle-color': '#4d4f53',
      'circle-radius': 3,
    },
  }

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/`}
    >
      <Layer {...layerProps} />
    </Source>
  )
}

PointsRemarquablesLine.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
}

export default PointsRemarquablesLine
