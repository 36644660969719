export const OBJECTS_KEYS = {
  zep: 'zep',
  pr: 'point_remarquable',
  prLine: 'point_remarquable_line',
  groupementZep: 'groupement_zep',
  signal: 'signal',
  jdz: 'joint_de_zone',
  cdv: 'circuit_de_voie',
  adv: 'appareil_de_voie',
  itineraire: 'itineraire',
  sel: 'catenaire',
  groupementSel: 'groupement_catenaire',
  tivSch: 'tivSch',
  tivGeo: 'tivGeo',
  poste: 'poste',
}

export const OBJECTS_LAYERS = {
  zep: 'map_midi_zep',
  pr: 'cassini_pointremarquable',
  prLine: 'phrit_pointremarquable',
  groupementZep: 'map_midi_groupementdezep',
  signal: 'map_midi_signal',
  jdz: 'map_midi_jointdezone',
  cdv: 'map_midi_circuitdevoie',
  adv: 'map_midi_brancheapv',
  itineraire: 'map_midi_itineraire',
  sel: 'map_midi_elementcatenaire',
  groupementSel: 'map_midi_groupementcatenaire',
  poste: 'map_midi_poste',
  tivSch: 'map_midi_tronconditinerairevoie',
  tivGeo: 'map_midi_tronconditinerairevoie',
}

export const OBJECTS_GEO = [
  {
    key: OBJECTS_KEYS.signal,
    sourceTable: OBJECTS_LAYERS.signal,
    label: 'Signaux',
    singular: 'Signal',
    pcid: 278,
    isEditable: true,
  },
  {
    key: OBJECTS_KEYS.jdz,
    sourceTable: OBJECTS_LAYERS.jdz,
    label: 'Joints de zone',
    singular: 'Joint de zone',
    isEditable: true,
    pcid: 274,
  },
  {
    key: OBJECTS_KEYS.adv,
    sourceTable: OBJECTS_LAYERS.adv,
    label: 'Appareils de voie',
    singular: 'Appareil de voie',
    pcid: 62,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.cdv,
    sourceTable: OBJECTS_LAYERS.cdv,
    label: 'Circuits de voie',
    singular: 'Circuit de voie',
    pcid: 293,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.zep,
    sourceTable: OBJECTS_LAYERS.zep,
    label: 'ZEP - Zones élémentaires de protection',
    singular: 'Zep',
    pcid: 249,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.groupementZep,
    sourceTable: OBJECTS_LAYERS.groupementZep,
    label: 'Groupements de ZEP',
    singular: 'Groupement de ZEP',
    pcid: 185,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.sel,
    sourceTable: OBJECTS_LAYERS.sel,
    label: 'SEL - Sections élémentaires',
    singular: 'Sel',
    pcid: 236,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.groupementSel,
    sourceTable: OBJECTS_LAYERS.groupementSel,
    label: 'Groupements de SEL',
    singular: 'Groupement de SEL',
    pcid: 291,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.itineraire,
    sourceTable: OBJECTS_LAYERS.itineraire,
    label: 'Itinéraires',
    singular: 'Itinéraire',
    pcid: 191,
    isEditable: true,
    isLinear: true,
  },
]

export const OBJECTS_SCH = [
  {
    key: OBJECTS_KEYS.signal,
    sourceTable: OBJECTS_LAYERS.signal,
    label: 'Signaux',
    singular: 'Signal',
    pcid: 278,
    isEditable: true,
  },
  {
    key: OBJECTS_KEYS.jdz,
    sourceTable: OBJECTS_LAYERS.jdz,
    label: 'Joints de zone',
    singular: 'Joint de zone',
    isEditable: true,
    pcid: 274,
  },
  {
    key: OBJECTS_KEYS.adv,
    sourceTable: OBJECTS_LAYERS.adv,
    label: 'Appareils de voie',
    singular: 'Appareil de voie',
    pcid: 62,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.cdv,
    sourceTable: OBJECTS_LAYERS.cdv,
    label: 'Circuits de voie',
    singular: 'Circuit de voie',
    pcid: 293,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.zep,
    sourceTable: OBJECTS_LAYERS.zep,
    label: 'ZEP - Zones élémentaires de protection',
    singular: 'Zep',
    pcid: 249,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.groupementZep,
    sourceTable: OBJECTS_LAYERS.groupementZep,
    label: 'Groupements de ZEP',
    singular: 'Groupement de ZEP',
    pcid: 185,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.sel,
    sourceTable: OBJECTS_LAYERS.sel,
    label: 'SEL - Sections élémentaires',
    singular: 'Sel',
    pcid: 236,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.groupementSel,
    sourceTable: OBJECTS_LAYERS.groupementSel,
    label: 'Groupements de SEL',
    singular: 'Groupement de SEL',
    pcid: 291,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.itineraire,
    sourceTable: OBJECTS_LAYERS.itineraire,
    label: 'Itinéraires',
    singular: 'Itinéraire',
    pcid: 191,
    isEditable: true,
    isLinear: true,
  },
  {
    key: OBJECTS_KEYS.pr,
    sourceTable: OBJECTS_LAYERS.pr,
    label: 'Points remarquables',
    singular: 'Point remarquable',
    isEditable: true,
  },
]

export const OBJECTPR = {
  key: OBJECTS_KEYS.prLine,
  sourceTable: OBJECTS_LAYERS.prLine,
  label: 'Points remarquables',
  singular: 'Point remarquable',
  isEditable: true,
}
