import PropTypes from 'prop-types'
import {
  Button, Col, Form, Row,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { MAP_TRACK_SOURCE } from 'common/Map/const'
import { OBJECTPR, OBJECTS_GEO, OBJECTS_SCH } from 'common/Map/Layers/LayersProperties'
import { updateDisplayedLayers, updateTrackedSource } from 'reducers/map'

const SettingsModal = ({ show, handleClose, popupClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { trackedSource, displayedLayers } = useSelector(state => state.map)

  const onLayerSelect = object => {
    console.log(object)
    popupClose()
    if (!displayedLayers.find(obj => obj.key === object.key)) {
      dispatch(updateDisplayedLayers([...displayedLayers, object]))
    } else {
      dispatch(updateDisplayedLayers(displayedLayers.filter(obj => obj.key !== object.key)))
    }
  }

  const onTrackedSourceChange = source => {
    popupClose()
    dispatch(updateTrackedSource(source))
  }

  return (
    <div className={`settings-modal ${show ? 'active' : ''}`}>
      <div className="d-flex justify-content-between">
        <div className="modal-title">{t('Map.mapSettings')}</div>
        <Button
          className="settings-close-button"
          onClick={handleClose}
        >
          <i className="icons-close" aria-hidden="true" />
        </Button>
      </div>
      <Col className="mt-4 h3 p-0">{t('Map.representationType')}</Col>
      <div className="dropdown-divider mb-2 w-100 ml-0" />
      <Row className="mt-2">
        <Col>
          <Form.Check id="schematic-radio" className="custom-control custom-radio">
            <Form.Check.Input
              type="radio"
              className="custom-control-input"
              onChange={() => onTrackedSourceChange(MAP_TRACK_SOURCE.schematic)}
              checked={trackedSource === MAP_TRACK_SOURCE.schematic}
            />
            <Form.Check.Label className="custom-control-label">{t('Map.schematic')}</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check id="geographic-radio" className="custom-control custom-radio">
            <Form.Check.Input
              type="radio"
              className="custom-control-input"
              onChange={() => onTrackedSourceChange(MAP_TRACK_SOURCE.geographic)}
              checked={trackedSource === MAP_TRACK_SOURCE.geographic}
            />
            <Form.Check.Label className="custom-control-label">Géographique à la voie</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check id="lineGeographic-radio" className="custom-control custom-radio">
            <Form.Check.Input
              type="radio"
              className="custom-control-input"
              onChange={() => onTrackedSourceChange(MAP_TRACK_SOURCE.lineGeographic)}
              checked={trackedSource === MAP_TRACK_SOURCE.lineGeographic}
            />
            <Form.Check.Label className="custom-control-label">Géographique à la ligne</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Col className="mt-4 h3 p-0">{t('Map.objectsLayers')}</Col>
      <div className="dropdown-divider mb-2 w-100 ml-0" />

      <Col>
        {trackedSource === (MAP_TRACK_SOURCE.schematic) && OBJECTS_SCH.map(object => (
          <Form.Check
            type="switch"
            label={object.label}
            id={object.key}
            onChange={() => onLayerSelect(object)}
            checked={displayedLayers.find(el => el.key === object.key)}
            key={object.key}
            className="mt-3 settings-check"
          />
        ))}
        {trackedSource === (MAP_TRACK_SOURCE.geographic) && OBJECTS_GEO.map(object => (
          <Form.Check
            type="switch"
            label={object.label}
            id={object.key}
            onChange={() => onLayerSelect(object)}
            checked={displayedLayers.find(el => el.key === object.key)}
            key={object.key}
            className="mt-3 settings-check"
          />
        ))}
        {trackedSource === MAP_TRACK_SOURCE.lineGeographic
          && (
            <Form.Check
              type="switch"
              label={OBJECTPR.label}
              id={OBJECTPR.key}
              onChange={() => onLayerSelect(OBJECTPR)}
              checked={displayedLayers.find(el => el.key === OBJECTPR.key)}
              key={OBJECTPR.key}
              className="mt-3 settings-check"
            />
          )}
      </Col>
    </div>
  )
}

SettingsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  popupClose: PropTypes.func.isRequired,
}
export default SettingsModal
