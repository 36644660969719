/* eslint-disable */

import React, { useState } from 'react'
import ReactMapGL from 'react-map-gl'
import { Popup as MapPopup } from 'react-map-gl'
import mapStyleJSON from 'assets/mapstyles/style_empty.json'
import { updateViewport } from 'reducers/map'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { MAP_TRACK_SOURCE } from 'common/Map/const'
import SettingsModal from 'components/Map/SettingsModal'
import SchematicView from 'components/Map/SchematicView'
import GeographicView from 'components/Map/GeographicView'
import LineGeographicView from 'components/Map/LineGeographicView'
import ObjectsLayer from 'components/Map/ObjectsLayer'
import PrLayer from 'components/Map/PrLayer'
import PopupContent from 'components/Map/PopupContent'

const styles = {
  map: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  settingButton: {
    padding: 0,
    fontSize: '1.25rem',
    border: '1px solid',
  },
}

const Map = () => {
  const dispatch = useDispatch()
  const { viewport, trackedSource, displayedLayers } = useSelector(state => state.map)
  const [show, setShow] = useState(false)
  const [showPopup, togglePopup] = useState(false)
  const [featureInfoHover, setFeatureInfoHover] = useState(undefined)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const popupClose = () => togglePopup(false)
  const onViewportChange = newViewport => {
    localStorage.setItem('newViewport', JSON.stringify(newViewport))
    dispatch(updateViewport(newViewport))
  }

  const onFeatureClick = e => {
    if (e?.features?.length > 0) {
      setFeatureInfoHover(e)
      togglePopup(true)
      console.log(e)
      setFeatureInfoHover(e)
    }
  }
  
  return (
    <main className="mastcontainer-map">
      <div className="btn-map-setting">
        <Button className="btn-rounded btn-rounded-white" style={styles.settingButton} onClick={handleShow}>
          <i className="icons-admin" aria-hidden="true" />
        </Button>
      </div>
      <SettingsModal show={show} handleClose={handleClose} popupClose={popupClose} />
      <ReactMapGL
        {...viewport}
        width={styles.map.width}
        height={styles.map.height}
        mapStyle={mapStyleJSON}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        clickRadius={2} // Click made easier !
        id="thea-map"
        getCursor={() => styles.map.cursor}
      >
        {trackedSource === MAP_TRACK_SOURCE.schematic ? (
          <>
            <SchematicView />
            {displayedLayers.length !== 0 && <ObjectsLayer sourceLayer="sch" />}
          </>
        ) : null}
        {trackedSource === MAP_TRACK_SOURCE.geographic ? (
          <>
            <GeographicView />
            {displayedLayers.length !== 0 && <ObjectsLayer sourceLayer="geo" />}
          </>
        ) : null}
        {trackedSource === MAP_TRACK_SOURCE.lineGeographic ? (
          <>
            <LineGeographicView />
            {displayedLayers.length !== 0 && <PrLayer sourceLayer="geo" />}
          </>
        ) : null}
        {showPopup && (<MapPopup
          longitude={featureInfoHover?.lngLat[0]}
          latitude={featureInfoHover?.lngLat[1]}
          closeButton={true}
          captureScroll={true}
          onClose={() => togglePopup(false)}
          className="mapboxgl-hover-custom-popup"
        >{PopupContent(featureInfoHover)}</MapPopup>)}
      </ReactMapGL>
    </main>
  )
}

export default Map
