import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'
import { getSignalLayerId } from 'utils/helpers'
import { ALL_SIGNALS_TYPES, SIGNALS_PANELS } from '../SignalsTypes'

const SignalsGeo = ({
  mapUrl, sourceLayer, sourceTable,
}) => {
  const point = () => ({
    id: `${sourceTable}Layer`,
    type: 'circle',
    minzoom: 9,
    'source-layer': sourceTable,
    paint: {
      'circle-color': '#0088ce',
      'circle-radius': 3,
    },
  })

  const signalsTosprites = type => {
    switch (type) {
      case 'TIV D FIXE':
        return ['concat', 'TIV D FIXE ', ['get', 'S_valeur']]
      case 'TIV D MOB':
        return ['concat', 'TIV D MOB ', ['get', 'S_valeur']]
      case 'TIV R MOB':
        return ['concat', 'TIV R MOB ', ['get', 'S_valeur']]
      case 'TIVD C FIX':
        return ['concat', 'TIVD C FIX ', ['get', 'S_valeur']]
      case 'TIVD B FIX':
        return ['concat', 'TIVD B FIX ', ['get', 'S_valeur']]
      case 'TIV PENDIS':
        return ['concat', 'TIV PENDIS ', ['get', 'S_valeur']]
      case 'TIV PENEXE':
        return ['concat', 'TIV PENEXE ', ['get', 'S_valeur']]
      case 'CHEVRON':
        return 'CHEVRON BAS'
      case 'ARRET VOY':
        return ['concat', 'ARRET VOY ', ['get', 'RA_libelle']]
      case 'DIVERS':
        return ['case',
          ['==', ['get', 'S_valeur'], 'SIGNAUX A GAUCHE'], 'SIG A GAUCHE',
          ['==', ['get', 'S_valeur'], 'SIGNAUX A DROITE'], 'SIG A DROITE',
          '',
        ]
      default:
        return type
    }
  }

  const signalMat = () => ({
    type: 'symbol',
    minzoom: 14,
    'source-layer': sourceTable,
    layout: {
      'text-field': '',
      'text-font': [
        'Roboto Condensed',
      ],
      'text-size': 9,
      'icon-image': ['case',
        ['==', ['get', 'LP_positionLocalisation'], 'D'], 'MATD',
        ['==', ['get', 'LP_positionLocalisation'], 'G'], 'MATG',
        '',
      ],
      'icon-size': 0.7,
      'icon-rotation-alignment': 'map',
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'angleGeo'],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
      'text-ignore-placement': true,
    },
  })

  const signalEmpty = (type, idLayer, angleName, iconOffset, libelle = 'S_valeur') => {
    const excludeText = ['SIGNAUX A GAUCHE', 'SIGNAUX A DROITE']

    return {
      id: idLayer,
      type: 'symbol',
      minzoom: 13,
      'source-layer': sourceTable,
      filter: ['==', 'TIF_mnemo', type],
      layout: {
        'text-field': ['case',
          ['in', ['get', libelle], ['literal', excludeText]], '',
          ['get', libelle],
        ],
        'text-font': [
          'SNCF',
        ],
        'text-size': 8,
        'text-offset': ['case',
          ['==', ['get', 'LP_positionLocalisation'], 'D'], ['literal', [1, -3]],
          ['==', ['get', 'LP_positionLocalisation'], 'G'], ['literal', [-1, -3]],
          ['literal', [0, 0]],
        ],
        'icon-offset': iconOffset,
        'icon-image': signalsTosprites(type),
        'icon-size': 0.5,
        'text-anchor': ['case',
          ['==', ['get', 'LP_positionLocalisation'], 'D'], 'left',
          ['==', ['get', 'LP_positionLocalisation'], 'G'], 'right',
          'center',
        ],
        'icon-rotation-alignment': 'map',
        'icon-pitch-alignment': 'map',
        'text-rotation-alignment': 'map',
        'icon-rotate': ['get', angleName],
        'text-rotate': ['get', angleName],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': '#333',
        'text-halo-width': 10,
        'text-halo-color': 'rgba(255,255,255,1)',
        'text-halo-blur': 0,
      },

    }
  }

  const signalPN = (angleName, iconOffset) => ({
    id: 'signal_pn',
    type: 'symbol',
    minzoom: 13,
    'source-layer': sourceTable,
    filter: ['==', 'TIF_mnemo', 'PN'],
    layout: {
      'text-field': '{RA_libelle}',
      'text-font': [
        'SNCF',
      ],
      'text-size': 8,
      'text-offset': ['case',
        ['==', ['get', 'LP_positionLocalisation'], 'D'], ['literal', [3.5, -3.5]],
        ['==', ['get', 'LP_positionLocalisation'], 'G'], ['literal', [-3.5, -3.5]],
        ['literal', [0, 0]],
      ],
      'icon-offset': iconOffset,
      'icon-image': 'VIDEN2',
      'icon-size': 0.5,
      'text-anchor': 'center',
      'icon-rotation-alignment': 'map',
      'icon-pitch-alignment': 'map',
      'text-rotation-alignment': 'map',
      'icon-rotate': ['get', angleName],
      'text-rotate': ['get', angleName],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#fff',
    },
  })

  const signal = type => {
    const angleName = 'angleGeo'

    const idLayer = getSignalLayerId(type)

    let size = 0.4
    let offsetY = -105
    let iconOffsetX = 55
    let textOffsetX = 3
    let isSignal = true
    if (SIGNALS_PANELS.indexOf(type) !== -1) {
      size = 0.4
      iconOffsetX = 55
      textOffsetX = 3
      offsetY = -60
      isSignal = false
    }

    const minZoom = 14

    const textOffset = ['case',
      ['==', ['get', 'LP_positionLocalisation'], 'D'], ['literal', [textOffsetX, -0.3]],
      ['==', ['get', 'LP_positionLocalisation'], 'G'], ['literal', [(textOffsetX * -1), -0.3]],
      ['literal', [2, 0]],
    ]

    const iconOffset = ['case',
      ['==', ['get', 'LP_positionLocalisation'], 'D'], ['literal', [iconOffsetX, offsetY]],
      ['==', ['get', 'LP_positionLocalisation'], 'G'], ['literal', [(iconOffsetX * -1), offsetY]],
      ['literal', [0, 0]],
    ]

    switch (type) {
      case 'REPER VIT':
        return signalEmpty(type, idLayer, angleName, iconOffset, 'RA_libelle')
      case 'DESTI':
      case 'DIVERS':
        return signalEmpty(type, idLayer, angleName, iconOffset)
      case 'PN':
        return signalPN(angleName, iconOffset)
      default:
    }

    return ({
      id: idLayer,
      minzoom: 11,
      type: 'symbol',
      'source-layer': sourceTable,
      filter: ['==', 'TIF_mnemo', type],
      layout: {
        'text-field': ['step',
          ['zoom'], '',
          minZoom, ['case', isSignal, ['get', 'RA_libelle'], ''],
        ],
        'text-font': [
          'Roboto Condensed',
        ],
        'text-size': 9,
        'text-offset': textOffset,
        'icon-offset': ['step', ['zoom'], ['literal', [0, 0]], minZoom, iconOffset],
        'icon-image': signalsTosprites(type),
        'icon-size': ['step',
          ['zoom'], (size / 2),
          minZoom, size,
        ],
        'text-anchor': ['case',
          ['==', ['get', 'LP_positionLocalisation'], 'D'], 'left',
          ['==', ['get', 'LP_positionLocalisation'], 'G'], 'right',
          'center',
        ],
        'icon-anchor': 'center',
        'icon-rotation-alignment': 'map',
        'icon-pitch-alignment': 'map',
        'text-rotation-alignment': 'map',
        'icon-rotate': ['get', angleName],
        'text-rotate': ['get', angleName],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': '#555',
        'text-halo-width': 3,
        'text-halo-color': 'rgba(255,255,255,0.75)',
        'text-halo-blur': 0,
      },

    })
  }

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/`}
    >
      <Layer {...signalMat()} />
      <Layer {...point()} />
      {ALL_SIGNALS_TYPES.map(sig => (
        <Layer key={sig} {...signal(sig)} />
      ))}
    </Source>
  )
}

SignalsGeo.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
}

export default SignalsGeo
