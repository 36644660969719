import React from 'react'
import { Source, Layer } from 'react-map-gl'
import { MAP_URL } from 'common/Map/const'
import geographicLayerData from 'common/Map/Layers/geographiclayers.json'

const LineGeographicView = () => {
  const { geoLineMainLayer } = geographicLayerData
  return (
    <>
      <Source
        type="vector"
        url={`${MAP_URL}/chartis/v2/layer/phrit_ligne/mvt/geo/`}
      >
        <Layer
          {...geoLineMainLayer}
          source-layer="phrit_ligne"
        />
      </Source>
    </>
  )
}

export default LineGeographicView
