import React from 'react'

import TopBar from 'components/TopBar'
import Map from 'views/Map'

const APP_NAME = 'THEA'

function Home() {
  return (
    <>
      <TopBar appName={APP_NAME} />
      <Map />
    </>
  )
}

export default Home
