import React from 'react'
import PropTypes from 'prop-types'
import {
  Source, Layer,
} from 'react-map-gl'

const LinearObjects = ({
  mapUrl, sourceLayer, sourceTable,
}) => {
  const layerProps = {
    id: `${sourceTable}Layer`,
    type: 'line',
    'source-layer': sourceTable,
    paint: {
      'line-color': '#cd0037',
      'line-width': 2,
    },
  }

  return (
    <Source
      id={`${sourceTable}-${sourceLayer}-source`}
      type="vector"
      url={`${mapUrl}/chartis/v2/layer/${sourceTable}/mvt/${sourceLayer}/`}
    >
      <Layer {...layerProps} />
    </Source>
  )
}

LinearObjects.propTypes = {
  mapUrl: PropTypes.string.isRequired,
  sourceLayer: PropTypes.string.isRequired,
  sourceTable: PropTypes.string.isRequired,
}

export default LinearObjects
